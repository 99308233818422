<template>
  <div>
    <ch-layout page button>
      <template #button>
        <ch-button @click="$refs.modal.openAddModal()" >新增</ch-button>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:imgUrl="scope">
            <el-image
                style="width: 40px; height: 40px;"
                :src="scope.row.imgUrl"
                :preview-src-list="[scope.row.imgUrl]" />
          </template>
          <template v-slot:handle="scope">
            <ch-button type="link" @click="$refs.modal.openEditModal(JSON.stringify(scope.row))">编辑</ch-button>
            <ch-button type="delete" @click="handleDelete(scope.row)">删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <modal ref="modal" :get-list-function="getTableList" />
  </div>
</template>

<script>
import {handleDelete} from "@/utils/utils";
// import {imgpr}
import modal from "./modal.vue"

export default {
  components: {modal},
  data(){
    return{
      tableList: [], // table列表
      isTableLoading: false, // table加载
      disabled: true,
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "头图名称", prop: "name", showOverflowTooltip: true,},
        { column: "slot", label: "头图照片", slotName: "imgUrl",prop: "imgUrl", },
        { column: "slot", label: "操作", slotName: "handle", width: "160" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      this.getTableList()
    },
    // 列表数据
    async getTableList(params=this.searchForm) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmImgTemplate/list",params).then((res) => {
        this.tableList = res.rows
        this.isTableLoading = false
      }).finally(() => {this.isTableLoading = false});
    },
    // 删除
    handleDelete(row) {
      handleDelete("/hm/hmImgTemplate/delete/", row.id).then(() => {
        this.getTableList();
      });
    },
  }
}
</script>

<style scoped>

</style>