<template>
  <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="500px" @open="open"
      @closed="closeModal">
    <ch-form
        :render-option="option"
        :model="modalForm"
        ref="form"
        :rules="rules"
        :props="{ labelWidth: '120px', paddingRight: '0px' }"
    >
      <template slot="imgUrl">
        <ch-upload-img
            action="/hm/general/upload"
            :img-length="1"
            :removeResponse="removeImgUrl"
            :uploadResponse="handleImgUrl"
            :fileList.sync="imgUrlFileList"
            :loading.sync="loading"
            :compress="false"
        >
          <div slot="tip">尺寸大小为800px*800px格式图片</div>
        </ch-upload-img>
      </template>
    </ch-form>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">取消</ch-button>
      <ch-button type="ok" @click="handleModal" :loading="loading">确定</ch-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function,
    }
  },
  data() {
    return {
      modalForm:{
        imgUrl: '',
        name: '',
      },
      title: '',
      isOpenModal: false,
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入头图名称', trigger: ['blur', 'change'] },
        ],
        imgUrl: [
          { required: true, message: '请上传头图模板', trigger: ['blur', 'change'] }
        ]
      },
      imgUrlFileList: [],
    }
  },
  computed: {
    option() {
      return [
        {type: 'input', label: '头图名称', prop: 'name', placeholder: '请输入头图名称',},
        {type: 'slot', label: '上传头图模板', slotName: 'imgUrl', prop: 'imgUrl',}
      ]
    }
  },
  methods: {
    open() {
      this.$refs.form.clearValidate();
    },
    openAddModal(){
      this.isOpenModal = true;
      this.title = '新增';
    },
    openEditModal(row){
      this.isOpenModal = true;
      this.title = '编辑';
      this.modalForm = JSON.parse(row);
      delete this.modalForm.delFlag;
      delete this.modalForm.companyId;
      this.imgUrlFileList = [{url: this.modalForm.imgUrl}]
    },
    handleImgUrl(res){
      this.modalForm.imgUrl = res.data.url;
    },
    removeImgUrl(){
      this.modalForm.imgUrl = ''
    },
    handleModal(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          if(this.modalForm.id){
            this.$curl.post('/hm/hmImgTemplate/edit', this.modalForm).then(() => {
              this.$message.success('编辑成功');
              this.getListFunction();
              this.closeModal();
            }).finally(() => {
              this.loading = false;
            })
          }else {
            this.$curl.post('/hm/hmImgTemplate/add', this.modalForm).then(() => {
              this.$message.success('新增成功');
              this.getListFunction();
              this.closeModal();
            }).finally(() => {
              this.loading = false;
            })
          }

        }
      })
    },
    closeModal() {
      this.$refs.form.resetFields();
      this.imgUrlFileList = [];
      // this.modalForm = {
      //   imgUrl: '',
      //   name: '',
      // }
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>